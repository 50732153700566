<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#">
                        <i class="fa fa-home"></i>
                        Dashboard
                    </a>
                </li>
                <li class="breadcrumb-item">
                    Vendor
                </li>
            </ol>
        </nav>
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <div class="col-sm-12">
                        <form class="card" @submit.prevent="submitForm()">
                            <div class="card-header">
                                <h3 class="card-title" v-if="status">New Vendor</h3>
                                <h3 class="card-title" v-else>Update Vendor</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="vendor_no">Vendor No *</label>
                                            <input disabled type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.name}" placeholder="Vendor No" v-model="vendor.vendor_no" />
                                            <span v-if="errors.vendor_no" class="invalid-feedback">{{ errors.vendor_no[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="vendor_name">Company Name *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.vendor_name}" placeholder="Company Name" v-model="vendor.vendor_name" ref="vendor_name" />
                                            <span v-if="errors.vendor_name" class="invalid-feedback">{{ errors.vendor_name[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="contact_person">Contact Person *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.contact_person}" placeholder="Contact Person" v-model="vendor.contact_person" ref="contact_person" />
                                            <span v-if="errors.contact_person" class="invalid-feedback">{{ errors.contact_person[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="mobile_no">Mobile Number *</label>
                                            <input type="number" class="form-control form-control-sm" :class="{'is-invalid': errors.mobile_no}" placeholder="Mobile Number" v-model="vendor.mobile_no" />
                                            <span v-if="errors.mobile_no" class="invalid-feedback">{{ errors.mobile_no[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="email">Email </label>
                                            <input type="email" class="form-control form-control-sm" :class="{'is-invalid': errors.email}" placeholder="Email Address" v-model="vendor.email" />
                                            <span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="pan_no">PAN Number</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.pan_no}" placeholder="PAN Number" v-model="vendor.pan_no" />
                                            <span v-if="errors.pan_no" class="invalid-feedback">{{ errors.pan_no[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="gst_no">GST Number</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.gst_no}" placeholder="GST Number" v-model="vendor.gst_no" />
                                            <span v-if="errors.gst_no" class="invalid-feedback">{{ errors.gst_no[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="category">Category</label>
                                            <search
                                                :class="{ 'is-invalid': errors.category_id }"
                                                :customClass="{ 'is-invalid': errors.category_id }"
                                                :initialize="vendor.category_id"
                                                id="category_id"
                                                label="category"
                                                placeholder="Select Category"
                                                :data="categories"
                                                @input=" category => vendor.category_id = category"
                                            >
                                            </search>
                                            <span v-if="errors.category_id" class="invalid-feedback">{{ errors.category_id[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="payment_term">Payment Term</label>
                                            <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.payment_term_id }" v-model="vendor.payment_term_id">
                                                <option value="">Select Payment Term</option>
                                                <option v-for="(payment_term,key) in payment_terms" :key="key" :value="payment_term.payment_term_id">{{ payment_term.payment_term}}</option>
                                            </select>
                                            <span v-if="errors.payment_term_id" class="invalid-feedback">{{ errors.payment_term_id[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="address">Address</label>
                                            <textarea type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.address}" placeholder="Address" v-model="vendor.address"></textarea>
                                            <span v-if="errors.address" class="invalid-feedback">{{ errors.address[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <router-link to="/vendors" class="btn btn-sm btn-outline-danger" type="button"> <i class="fas fa-ban"></i> Discard </router-link>
                                    <button class="btn btn-sm btn-outline-success" type="submit">
                                        <span v-if="status"> <i class="fas fa-save"></i> Submit </span>
                                        <span v-else> <i class="fas fa-save"></i> Update </span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Search from "@/components/Search.vue";
    export default {
        name: "Vendors.Create",
        components: { Search },
        data() {
            return {
                status: true,
                vendor: {
                    branch_id: this.$store.getters.user.branch_id,
                    vendor_no: "",
                    vendor_name: "",
                    contact_person:"",
                    mobile_no: "",
                    email: "",
                    pan_no: "",
                    gst_no: "",
                    address: "",
                    category_id:"",
                    payment_term_id:"",
                },
                payment_terms: [],
                categories: [],
                errors: [],
            };
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.getPaymentTerms();
                if (to.name == "Vendors.Create") {
                    let uri = { uri: "getVendorNo", data: vm.vendor };
                    vm.$store.dispatch("post", uri)
                    .then(function (response) {
                        vm.vendor.vendor_no = response.data;
                        vm.$refs.vendor_name.focus();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                } 
                else {
                    vm.status = false;
                    let uri = { uri: "showVendor", data: { vendor_id: to.params.vendor_id } };
                    vm.$store.dispatch("post", uri)
                    .then(function (response) {
                        vm.vendor = response.data.data;
                        vm.$refs.vendor_name.focus();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            });
        },
        methods: {
            submitForm() {
                let vm = this;
                if (vm.status) {
                    vm.create();
                } else {
                    vm.update();
                }
            },
            getPaymentTerms() {
                let vm = this;
                vm.$store
                .dispatch("post", { uri: "getPaymentTerms" })
                .then(function (response) {
                    vm.payment_terms = response.data.data;
                    vm.getCategories()
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getCategories() {
                let vm = this;
                vm.$store
                .dispatch("post", { uri: "getCategories" })
                .then(function (response) {
                    vm.categories = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            create() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "addVendor", data: vm.vendor };
                vm.$store.dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Vendor is successfully created");
                    vm.$router.push("/vendors");
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            update() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateVendor", data: vm.vendor };
                vm.$store.dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Vendor is successfully updated");
                    vm.$router.push("/vendors");
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },
        },
    };
</script>
